<template>
	<b-container fluid class="pagecontainer">
		<b-row v-if="error">
			<b-col>
				<b-alert variant="danger" v-if="error" show dismissible>
					ERROR: {{error}}
				</b-alert>
			</b-col>
		</b-row>
		<b-row v-else-if="isprocessing">
			<b-col>
				<b-spinner variant="success" label="loading..." />
			</b-col>
		</b-row>
		<b-row v-else-if="user">
			<b-col>
				<b-card header-tag="header" footer-tag="footer" style="min-height: 75vh;">
					<template #header>
						<b-media>
							<template #aside>
								<div>
									<input ref="avatarupload" type="file" @change="onAvatarFileChange" style="display:none">
									<b-avatar :src="useravatar" size="6rem" @click.native="uploadAvatar"></b-avatar>
									<b-btn class="float-right" size="sm" variant="danger" @click="removeAvatar">
										<fa-icon icon="times" />
									</b-btn>
								</div>
							</template>

							<div class="d-flex">
								<div class="p-2 flex-fill">
									<span class="pl-4 h4">User - {{user.id}}</span>
								</div>
								<div class="p-2">
									<b-btn variant="success" @click="doGetUser">
										<fa-icon icon="sync" />
									</b-btn>
								</div>
							</div>
						</b-media>
					</template>
					<b-card-body>
						<b-card-title>Details</b-card-title>
						<b-card-text>
							<b-form inline>
								<label class="sr-only" for="inline-form-input-name">Name</label>
								<b-form-input v-model.trim="user.userName"
											  id="inline-form-input-name"
											  class="mb-4 mr-sm-4 mb-sm-0"
											  placeholder="name"></b-form-input>

								<label class="sr-only" for="inline-form-input-email">Email</label>
								<b-input-group prepend="@" class="mb-4 mr-sm-4 mb-sm-0">
									<b-form-input v-model.trim="user.email"
												  id="inline-form-input-email"
												  placeholder="email"></b-form-input>
								</b-input-group>
							</b-form>
						</b-card-text>

						<b-card-title>Roles</b-card-title>
						<b-card-text>
							{{user.rolesList}}
						</b-card-text>

						<b-card-title>Claims</b-card-title>
						<b-card-text>
							{{user.claimsList}}
						</b-card-text>
					</b-card-body>
					<!--
						<b-button href="#" variant="primary">Go somewhere</b-button>
						<template #footer>
							<em>...</em>
						</template>
					-->
				</b-card>
			</b-col>
		</b-row>
	</b-container>
</template>
<script lang="js">
	import { mapState, mapActions } from 'vuex';
	import DEBUG from '@/common/DEBUG';

	export default {
		name: 'manage_users',

		data() {
			return {
				baseurl: '//' + window.location.hostname,

				id: null,
				error: null,
				isprocessing: false,
				file: null,
				user: null,
				useravatar: null,
			}
		},

		async mounted() {
			const that = this
			that.id = that.$route.params.id;
			await that.doGetUser();
		},

		computed: {
			...mapState(["userinfo", "token"]),
		},

		methods: {
			...mapActions([
				"getUser", "getUserAvatar", "uploadUserAvatar", "DeleteUserAvatar"
			]),

			async doGetUser() {
				const that = this
				const id = that.id;
				const ts = '1'; // TODO

				that.error = null;
				try {
					that.isprocessing = true;

					that.user = await this.getUser(id);
					DEBUG.log('doGetUser - RESULTS - ', that.user)

					that.useravatar = await this.getUserAvatar({ id, ts });
				} catch (error) {
					that.error = JSON.stringify(error);
					that.notifyError(`an error occurred - ${that.error}`);
				} finally {
					that.isprocessing = false;
				}
			},

			uploadAvatar() {
				this.$refs.avatarupload.click();
			},

			async onAvatarFileChange(e) {
				var files = e.target.files || e.dataTransfer.files;
				if (!files.length)
					return;

				const file = files[0];
				await this.uploadUserAvatar(file);
			},

			async removeAvatar() {
				await this.DeleteUserAvatar(that.id)
				this.useravatar = null;
			},
		}
	}
</script>
<style>
	.pagecontainer {
		max-height: 85vh;
		overflow-y: auto;
	}
</style>